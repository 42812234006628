import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/real-world/Heading';
import { QuoteImageProps } from 'components/core/Quote';

interface Props {
  data: {
    quoteImageKhashayar: QuoteImageProps;
    studentPracticumImage: Core.ImageAttributes;
    kevinJung: Core.ImageAttributes;
    greenTransit: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { studentPracticumImage, kevinJung, greenTransit } = props.data;

  const headingSections: {
    title: string;
    slug: string;
    description: string;
  }[] = [
    {
      title: 'Student Practicum',
      slug: 'student-practicum',
      description:
        'Farmers were eager to share their challenges, such as their need for irrigation, lack of access to credit, and limited market access.'
    },
    {
      title: 'Global Careers Workshop',
      slug: 'global-careers-workshop',
      description:
        'The workshop was part of Deloitte’s strategy “to go even deeper and identify the best candidates at SAIS” even earlier in the recruiting process.'
    }
  ];

  return (
    <Layout departmentSlug="real-world" title="Real World">
      <Heading
        title="Identifying ways to help small farmers in India market their crops more profitably … Partnering to provide on-campus recruiting and experiential learning."
        headingSections={headingSections}
      />

      <Core.ContentWrapper>
        <Core.ContentSection id="student-practicum">
          <Core.Flag>Student Practicum</Core.Flag>
          <Core.PrimaryHeading>Market Forces in India</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>At $271 billion each year</Core.LeadIn>, India’s
            agricultural sector is immense. Are there ways that the country’s
            smaller farmers can more profitably navigate its vast scale?{' '}
          </p>
          <p>
            Second-year SAIS international development students Chloe
            Hauenstein, Jamie Lee, Maya Gainer, and Yadian Chen traveled to
            India earlier this year to research whether creating farmer producer
            organizations to market their crops might be a way to do so.{' '}
          </p>
          <p>
            Their travels took them to the Maharajganj district of the state of
            Uttar Pradesh to witness the challenges faced by small farmers up
            close. Many growers in the region work piecemeal subsistence tracts
            that yield little marketable surplus. The bulk of the profits from
            what these farmers do sell is captured by the middle men who broker
            the deals.{' '}
          </p>
          <Core.FullWidthImage
            image={studentPracticumImage}
            alt="Student practicum, India"
          />
          <p>
            The practicum team collaborated with Chennai-based consultancy firm
            Athena Infonomics and Grameen Development Services, a
            nongovernmental organization with two decades of experience working
            with farmers in Maharajganj. They scaled down their broader
            proposal, observes Hauenstein, who answered questions by email with
            the group’s input, to focus on “the role FPOs can play in allowing
            farmers to gain higher prices and more consistent incomes.”
          </p>
          <p>
            Once in Maharajganj, the practicum team talked directly with farmers
            to gauge the possibilities for change. “Farmers were eager to share
            their challenges, such as their need for irrigation, lack of access
            to credit, and limited market access,” writes Hauenstein.
          </p>
          <p>
            The team also identified the need for better training in business
            and marketing skills as another pressing need for regional farmers.
            Hauenstein adds that “it was a great opportunity to get out of the
            bubble and interact with the people whose lives we study at SAIS.”
          </p>
          <p>
            Tanvi Nagpal, director and associate practitioner in residence for
            the International Development program, says that the students in
            SAIS practicums bring diverse skills and experiences that provide
            “value at a higher level for clients who have a conundrum or an
            issue.” The analysis provided by practicum teams provides “academic
            rigor and excellence at a master’s level to independently assess a
            new problem.”{' '}
          </p>
          <p>
            The students also talked with agricultural policy experts in New
            Delhi and elsewhere. These interviews often elicited new referrals
            to other experts who were eager to engage in the search for
            solutions.{' '}
          </p>
          <p>
            People in this field recognize that the status quo needs to be
            improved,” writes Hauenstein. “They are excited that other
            researchers are working on recommendations.
          </p>
          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="student-practicum" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="global-careers-workshop"
          marginalia={
            <Core.FullWidthImage
              image={kevinJung}
              alt="Kevin Jung"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <Core.Flag>Global Careers Workshop</Core.Flag>
          <Core.PrimaryHeading>
            Building Career Infrastructure
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Second-year SAIS student</Core.LeadIn> Chae Young
            “Kevin” Jung is taking full advantage of opportunities to develop
            his expertise in the infrastructure finance sector. His time at the
            school has included a stint with project finance advisory firm
            Astris Finance as well as a summer internship with Chile’s Ministry
            of Energy.{' '}
          </p>
          <p>
            “I have a long-term goal of bringing clean energy to public
            transportation through a working partnership between public and
            private sectors,” he says.{' '}
          </p>
          <p>
            Another opportunity for Jung and other students to further their
            working knowledge came this past semester through SAIS Global
            Careers, which hosted a one-day seminar on campus by management
            consulting firm Deloitte that provided a detailed glimpse into the
            company’s methodologies and practices.{' '}
          </p>
          <Core.FullWidthImage image={greenTransit} alt="Green Transit" />
          <p>
            SAIS Global Careers Director Jean-Amiel Jourdan says that the
            invitation-only February workshop, Evaluating U.S. Infrastructure
            Projects, came about because of the school’s strong existing
            relationship with Deloitte. A number of alumni work at the firm, and
            Deloitte actively recruits at SAIS each year for both its commercial
            and federal practices. Jourdan observes that the workshop was part
            of Deloitte’s strategy “to go even deeper and identify the best
            candidates at SAIS” even earlier in the recruiting process.
          </p>
          <p>
            “It’s one of the best partnerships we could dream of, as it yields
            experiential learning and on-campus recruiting at the same time,”
            says Jourdan. “It’s built on a long-term relationship and trust.”{' '}
          </p>
          <p>
            Students who attended the event received significant and tangible
            insights into how Deloitte evaluates infrastructure investments.
            Jung adds that a highlight of the day was the opportunity for
            attendees to deploy what they had learned in the seminar.
          </p>
          <p>
            “We had a chance to use their model to evaluate a hypothetical
            project,” says Jung, who also served as a liaison between the firm
            and SAIS Global Careers during the seminar.{' '}
          </p>
          <p>
            Jung says the seminar meshed perfectly with the trajectory of his
            own goals to merge sectors in pursuit of clean energy. His
            internship in Chile’s energy ministry was focused on how the public
            sector evaluated the prospect of integrating electric buses into its
            transit network, and the Deloitte seminar and his work at Astris
            Finance are a chance to get a sense of private-sector practices.{' '}
          </p>
          <p>
            “It was a good experience,” he says, “to work with leading
            professionals from a leading management consulting firm.”
          </p>
          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="global-careers-workshop" />
        </Core.ContentSection>

        <Core.MoreResources>
          For more about students’ experiences in the field, visit{' '}
          <a
            href="https://snapshot.sais-jhu.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            snapshot.sais-jhu.edu
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    studentPracticumImage: file(
      relativePath: { regex: $directory }
      name: { eq: "india-practicum" }
    ) {
      ...FullWidthImage
    }
    kevinJung: file(
      relativePath: { regex: $directory }
      name: { eq: "kevin-jung" }
    ) {
      ...MarginaliaImage
    }
    greenTransit: file(
      relativePath: { regex: $directory }
      name: { eq: "green-transit" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
