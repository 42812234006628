import './Heading.scss';

import React from 'react';
import { Link } from 'gatsby';

import * as Core from 'components/core';

import { useCurrentIssue } from 'context/IssueContext';

interface Props {
  title: string;
  headingSections: {
    title: string;
    slug: string;
    description: string;
  }[];
}

const RealWorldHeading: React.FC<Props> = (props) => {
  const { title, headingSections } = props;

  const { issue: currentIssue } = useCurrentIssue();

  return (
    <Core.Masthead className="RealWorldHeading" color="dark">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Real World</Core.DepartmentLabel>
        <Core.Flag>Beyond the Classroom</Core.Flag>
        <h1 className="ReadWorldHeading-title">{title}</h1>
        <div className="RealWorldHeading-index">
          {headingSections.map((section) => (
            <Link
              key={section.slug}
              to={`/${currentIssue.slug}/real-world#${section.slug}`}
              className="RealWorldHeading-index-item"
            >
              <div className="RealWorldHeading-index-item-title">
                {section.title}
              </div>
              <p>{section.description}</p>
            </Link>
          ))}
        </div>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default RealWorldHeading;
